<template>
  <article
    v-if="!loading"
    class="box"
  >
    <h1 class="page-title">
      {{ localDocumentTitle }}
    </h1>

    <VCard class="pa-4">
      <section class="certificate-info">
        <header class="certificate-info__header">
          <h2>{{ $t('$app.basic_information') }}</h2>

          <CertificateFiles
            v-if="certificate.files_count"
            :certificate="certificate"
            classes="certificate-info__action"
          />

          <VBtn
            v-else-if="!vendor?.branding?.hide_printable_listener_certificate"
            class="certificate-info__action"
            color="primary"
            elevated
            :href="certificatePdfUri"
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            Скачать в PDF
          </VBtn>
        </header>

        <div
          v-if="certificate.number"
          class="certificate-info__option"
        >
          <label>{{ $t('numbers.one') }}: </label>
          {{ certificate.number }}
        </div>

        <div
          v-if="certificate.type?.title"
          class="certificate-info__option"
        >
          <label>Тип документа: </label>
          {{ certificate.type?.title }}
        </div>

        <div
          v-if="certificate.is_signed"
          class="certificate-info__option"
        >
          <label>Дата выдачи: </label>
          <time :datetime="certificate.signed_at">{{ formatDate(certificate.signed_at) }}</time>
        </div>

        <div
          v-if="new UDate(certificate.expires_at).valueOf()"
          class="certificate-info__option"
        >
          <label>Срок действия истекает: </label>
          <time :datetime="certificate.expires_at">{{ formatDate(certificate.expires_at) }}</time>
        </div>

        <div
          v-if="certificate.course_id"
          class="certificate-info__option"
        >
          <label>{{ $t('courses.one') }}: </label>
          {{ certificate.course_title }}
        </div>

        <div
          v-if="certificate.settings?.degree"
          class="certificate-info__option"
        >
          <label>Квалификация: </label>
          {{ certificate.settings.degree }}
        </div>

        <div v-if="certificate.settings?.tracking_url">
          <label>Вам отправлен оригинал документа, скопируйте ссылку (номер) для отслеживания, чтобы узнать статус: </label>
          <a
            v-if="isUrl(certificate.settings.tracking_url)"
            :href=" certificate.settings.tracking_url"
            target="_blanck"
          >
            {{ certificate.settings.tracking_url }}
          </a>

          <template v-else>
            {{ certificate.settings.tracking_url }}
          </template>
        </div>

        <CertificateFiles
          v-if="certificate.files_count"
          :certificate="certificate"
          classes="certificate-info__action mt-4"
        />

        <VBtn
          v-else-if="!vendor?.branding?.hide_printable_listener_certificate"
          class="certificate-info__action mt-4"
          color="primary"
          elevated
          :href="certificatePdfUri"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          Скачать в PDF
        </VBtn>
      </section>

      <template v-if="fisFrdoHasFields || certificate.settings?.fgissout_program">
        <VDivider />
        <section class="certificate-info">
          <header class="mb-4">
            <h2>Дополнительная информация</h2>
          </header>

          <VCard color="#f5f5f5">
            <template v-if="fisFrdoHasFields">
              <VCardTitle>
                Данные для ФИС ФРДО
              </VCardTitle>

              <VCardText>
                <VCard class="pa-4">
                  <VRow>
                    <VCol
                      v-if="certificate.settings?.fisfrdo_number"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>{{ $t('numbers.one') }}: </label>
                        <br>
                        {{ certificate.settings?.fisfrdo_number }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_series"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>{{ $t('series.one') }}: </label>
                        <br>
                        {{ certificate.settings?.fisfrdo_series }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_status"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>{{ $t('statuses.one') }}: </label>
                        <br>
                        {{ certificate.settings?.fisfrdo_status }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_confirmation_loss"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Подтверждение утраты: </label>
                        <br>
                        {{ certificate.settings?.fisfrdo_confirmation_loss }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_confirmation_change"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Подтверждение обмена: </label>
                        <br>
                        {{ certificate.settings?.fisfrdo_confirmation_change }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_confirmation_destruction"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Подтверждение уничтожения: </label>
                        <br>
                        {{ certificate.settings?.fisfrdo_confirmation_destruction }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="new UDate(certificate.settings?.session_starts_at).valueOf()"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>{{ $t('session_starts_at.one') }}: </label>
                        <br>
                        <time :datetime="certificate.settings.session_starts_at">{{ formatDate(certificate.settings.session_starts_at) }}</time>
                      </div>
                    </VCol>

                    <VCol
                      v-if="new UDate(certificate.settings?.session_ends_at).valueOf()"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>{{ $t('session_ends_at.one') }}: </label>
                        <br>
                        <time :datetime="certificate.settings.session_ends_at">{{ formatDate(certificate.settings.session_ends_at) }}</time>
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.hours > 0"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Срок обучения (в часах): </label>
                        <br>
                        {{ certificate.hours }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_program"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Профессиональная программа: </label>
                        <br>
                        {{ certificate.settings.fisfrdo_program }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_profession"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Наименование профессии рабочего, должности служащего: </label>
                        <br>
                        {{ certificate.settings.fisfrdo_profession }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_group_spec"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Укрупненные группы специальностей: </label>
                        <br>
                        {{ certificate.settings.fisfrdo_group_spec }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_parent_program"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Наименование области профессиональной деятельности: </label>
                        <br>
                        {{ certificate.settings.fisfrdo_parent_program }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_qualification_class"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Присвоенный квалификационный разряд, класс, категория: </label>
                        <br>
                        {{ certificate.settings.fisfrdo_qualification_class }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_qualification"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Наименование квалификации, профессии, специальности: </label>
                        <br>
                        {{ certificate.settings.fisfrdo_qualification }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_education_venue"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Форма получения образования на момент прекращения образовательных отношений: </label>
                        <br>
                        {{ certificate.settings.fisfrdo_education_venue }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_education_medium"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Форма обучения: </label>
                        <br>
                        {{ certificate.settings.fisfrdo_education_medium }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_education_paid_by"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Источник финансирования обучения: </label>
                        <br>
                        {{ certificate.settings.fisfrdo_education_paid_by }}
                      </div>
                    </VCol>

                    <VCol
                      v-if="certificate.settings?.fisfrdo_previous_certificate_fullname"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <div>
                        <label>Подтверждение квалификации: </label>
                        <br>

                        <template v-if="certificate.settings.fisfrdo_previous_certificate_series">
                          Серия {{ certificate.settings.fisfrdo_previous_certificate_series }}
                        </template>

                        <template v-if="certificate.settings.fisfrdo_previous_certificate_number">
                          №&nbsp; {{ certificate.settings.fisfrdo_previous_certificate_number }}
                        </template>

                        <template v-if="certificate.settings.fisfrdo_previous_certificate_edu">
                          {{ certificate.settings.fisfrdo_previous_certificate_edu }}
                        </template>
                      </div>
                    </VCol>
                  </VRow>
                </VCard>
              </VCardText>
            </template>

            <template v-if="certificate.settings?.fgissout_program && fgissout.programs">
              <VCardTitle>
                Данные для ФГИС СОУТ
              </VCardTitle>

              <VCardText>
                <VCard class="pa-4">
                  <div v-if="certificate.settings?.fgissout_program">
                    <label>Программа по&nbsp;охране труда для реестра Минтруда и&nbsp;ФГИС СОУТ: </label>
                    {{ fgissout.programs.find(p => p.id === certificate.settings.fgissout_program).title }}
                  </div>
                </VCard>
              </VCardText>
            </template>
          </VCard>
        </section>
      </template>
    </VCard>
  </article>

  <VProgressCircular
    v-else
    :size="50"
    color="primary"
    indeterminate
    class="app-loader"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import * as getters from '@/store/getters/types'
import { getCertificate } from '@/api/api'
import documentTitle from '@/mixins/documentTitle'
import { UDate } from '@/utils/date'
import { request } from '@/utils/request'
import CertificateFiles from '@/components/CertificateFiles.vue'

export default {
  name: 'TheHistory',

  components: {
    CertificateFiles,
  },

  mixins: [documentTitle],

  data () {
    return {
      documentTitle: '',
      certificate: {},
      fgissout: {},
      loading: true
    }
  },

  computed: {
    ...mapGetters({
      vendor: getters.VENDOR,
    }),
    certificatePdfUri () {
      return `/certificates/${this.certificate.id}/content?_fmt=pdf`
    },

    fisFrdoHasFields () {
      const hasField = new UDate(this.certificate.settings?.session_starts_at).valueOf() ||
                       new UDate(this.certificate.settings?.session_ends_at).valueOf() ||
                       this.certificate.hours > 0

      if (hasField) return true

      return [
        'fisfrdo_number',
        'fisfrdo_series',
        'fisfrdo_status',
        'fisfrdo_confirmation_loss',
        'fisfrdo_confirmation_change',
        'fisfrdo_confirmation_destruction',
        'fisfrdo_program',
        'fisfrdo_profession',
        'fisfrdo_group_spec',
        'fisfrdo_parent_program',
        'fisfrdo_qualification_class',
        'fisfrdo_qualification',
        'fisfrdo_education_venue',
        'fisfrdo_education_medium',
        'fisfrdo_education_paid_by',
        'fisfrdo_previous_certificate_fullname',
      ].some(key => this.certificate?.settings[key])
    }
  },

  created () {
    this.register()
  },

  methods: {
    UDate,
    formatDate: UDate.formatDate,

    isUrl (string) {
      let url = {}

      try {
        url = new URL(string)
      } catch {
        return false
      }

      return url.protocol === 'http:' || url.protocol === 'https:'
    },

    async register () {
      const id = this.$route.params.id

      if (!id) return

      this.loading = true

      try {
        const { certificate } = await getCertificate(id)

        if (certificate) {
          this.certificate = certificate
          this.documentTitle = certificate.title
        }

        // Загрузим условно сегодняшнюю версию словаря ФГИС СОУТ.
        this.fgissout = await request('/assets/fgissout/lists.json?_ts=' + (new Date()).toISOString().substring(0, 10))
      } finally {
        this.loading = false
      }
    },

  }
}
</script>

<style lang="scss">
.certificate-info {
  margin: 1.5rem 0;
}

.certificate-info__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 1rem;

  .certificate-info__action {
    display: none;

    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.certificate-info__action {
  display: flex;
  width: 100%;

  @include media-breakpoint-up(sm) {
    display: none;
    width: auto;
  }
}

.certificate-info__option {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: $spacer * 2;
  margin-bottom: $spacer * 2;

  &:last-of-type {
    margin-bottom: $spacer * 4;
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    flex-direction: row;
  }

  label {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      flex: 0 0 16rem;
    }
  }
}
</style>
